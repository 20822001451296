
export class Config {

    static organizationId = '641c5cd78246210ed0195b73';
    static organizationName = "Damask Resto market";
    static appVersion = '2.34.00';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];
    static zendeskAccountUrl = 'moduurnhelp.zendesk.com';
    static zendeskUserField=1500009211882;
    static zendeskUserFormId= 1500002580982;
    
    constructor() { }
}
